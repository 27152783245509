import React, { Component } from 'react';
import './IndividualTrial.scss';
import { firstBy } from "thenby";
import { getTrialsCategory, getPURL} from '../../../../selectors/trialsSelector';
import { connect } from 'react-redux';
import { trialsActionCreators } from '../../../../actions/trialsAction';
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nodeserver_url } from '../../../../constants/api';
import axios from 'axios';


const TrialsCategory = getTrialsCategory();

class IndividualTrial extends Component {

	constructor(props) {
		super(props);
		this.state = {
			PURL: getPURL()
		}
	}

	
	// get trial color
	getTrialColor = (trialType) => {
		switch (trialType) {
			case 'InVigor Straight Cut vs Swathed':
				return '#328bd2';
			case 'InVigor Straight Cut Only Trials':
				return '#c20000';
			case 'InVigor Standard Swathed Trials':
				return '#e0a735';
			case 'InVigor Clubroot Trials':
				return '#7c0084';
			case 'Incomplete Trials':
				return '#f0767c';
			default:
				return 'grey';
		}
	}

	// get trial color - , background: `${h.is_basf ? this.getTrialColor(trial.data.trial_type, false) : "#a3a1a2"}` 
	getTrialBackground = (trialTypebackground) => {
		switch (trialTypebackground) {
			case 'InVigor Straight Cut vs Swathed':
				return '#bde0fc';
			case 'InVigor Straight Cut Only Trials':
				return '#f8e3db';
			case 'InVigor Standard Swathed Trials':
				return '#fcf7ed';
			case 'InVigor Clubroot Trials':
				return '#f1e1e4';
			case 'Incomplete Trials':
				return '#f3c3c6';
			default:
				return 'grey';
		}
	}

	generateTracker = (event, action, category) => {
		//console.log("GT")
        if (this.state.PURL) {
            console.log(this.state.PURL)
            let apiBaseUrl = `${nodeserver_url}`;

            /*Start of call */
            let data = {
                event,
                action,
                category
            };

            axios
                .post(apiBaseUrl + `/trial_results/api/sql/marketingAutomation/${data.event}/${data.action}/${data.category}?PURL=${this.state.PURL}`, data)
        }

	}
	
	componentDidMount = () => {
        if (this.props.clearPURL) {
            this.setState({
                ...this.state,
                PURL: null
            }, () => { })
        }
    }

	getYieldChart = (trial, swathType, swathTypeAbbr) => {
		let maxYield;
		maxYield = (trial && trial.hybrids) ? this.getMaxYield(trial.hybrids) : 0;
		if (swathType) {
			let trialFilteredForSwathType = trial.hybrids.filter(h => {
				return h.swath_type === swathType;
			})

			maxYield = (trial && trial.hybrids) ? this.getMaxYield(trialFilteredForSwathType) : 0;
			return (maxYield ?
				<div key={`${trial.data.trial}-${swathType}`}>
					{trialFilteredForSwathType.sort(firstBy("is_basf", -1).thenBy("hybrid_sequence"))
						.map((h, i) => {
							// if (h.hybrid.includes("®")) {
							// 	let str = h.hybrid.split("®")
							// 	h.hybrid = `${str[0]}<sup>®</sup>${str[1]}`
							// }
							return (
								<div key={`barItemMult-${h.hybrid}-${i}-${h.dockage}-${h.net_yield}-${h.trial}-${h.maturity}`} className="chartItemContainer">
									<div className="barContainer">
										<div className="itemText" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div>
										<div className="itemBarContainer">
											<div className="itemBar" style={{ width: `${(h.net_yield / maxYield) * 100}%`, background: `${h.is_basf ? "#0061a1" : "#a3a1a2"}` }} >{h.net_yield ? h.net_yield.toFixed(1) : h.net_yield}</div>
										</div>
									</div>
								</div>
							)
						})}
				</div>
				: null)
		}
		else {
			maxYield = (trial && trial.hybrids) ? this.getMaxYield(trial.hybrids) : 0;
			return (maxYield ?
				<React.Fragment>
					{trial.hybrids.sort(firstBy("is_basf", -1).thenBy("hybrid_sequence"))
						.map((h, i) => {
							// if (h.hybrid.includes("®")) {
							// 	let str = h.hybrid.split("®")
							// 	h.hybrid = `${str[0]}<sup>®</sup>${str[1]}`
							// }
							return (
							<div key={`barItem-${h.hybrid}-${i}-${h.net_yield}`} className="chartItemContainer">
								<div className="barContainer">
									<div className="itemText" dangerouslySetInnerHTML={{ __html: h.hybrid }}></div>
									<div className="itemBarContainer">
										<div className="itemBar" style={{ width: `${(h.net_yield / maxYield) * 100}%`, background: `${h.is_basf ? "#0061a1" : "#a3a1a2"}` }} ></div>
									</div>
									<div className="itemBarYield"><p>{h.net_yield ? h.net_yield.toFixed(1) : h.net_yield}</p></div>
								</div>
							</div>
							)}
						)}
				</React.Fragment>
				: null)
		}
	}

	//sorted by hybrid sequence
	getYieldChartapt = (trial, treatmentType) => {
		let maxYield;
		maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trial.treatments) : 0;
		if (treatmentType) {
			let trialFilteredFortreatmentType = trial.treatments.filter(h => {
				return h.swath_type === treatmentType;
			})
			maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trialFilteredFortreatmentType) : 0;
			return (maxYield && !this.props.blockYield ?
				<div key={`${trial.data.trial}-${treatmentType}`}>
					{trialFilteredFortreatmentType.sort(firstBy("is_basf", -1).thenBy("treatment"))
						.map((h, i) => {
							return (
								<div key={`barItemMult-${h.treatment}-${i}-${h.yield}-${h.trial}-${h.maturity}`} className="chartItemContainer">
									<div className="barContainer">
										<div className="itemText">{h.displayed_treatment}</div>
										<div className="itemBarContainer">
											<div className="itemBar" style={{ width: `${(h.yield / maxYield) * 100}%`, background: `${h.is_basf ? "#65ac1e" : "#a3a1a2"}` }} >{h.yield ? h.yield.toFixed(1) : h.yield}</div>
										</div>
									</div>
								</div>
							)
						})}
				</div>
				: null)
		}
		else {
			maxYield = (trial && trial.treatments) ? this.getMaxYieldapt(trial.treatments) : 0;
			return (maxYield && !this.props.blockYield ?
				<React.Fragment>
					{trial.treatments.sort(firstBy("is_basf", -1).thenBy("treatment"))
						.map((h, i) => (
							<div key={`barItem-${h.treatment}-${i}-${h.yield}`} className="chartItemContainer">
								<div className="barContainer">
									<div className="itemText">{h.displayed_treatment}</div>
									<div className="itemBarContainer">
										<div className="itemBar" style={{ width: `${(h.yield / maxYield) * 100}%`, background: `${h.is_basf ? "#65ac1e" : "#a3a1a2"}` }} >{h.yield ? h.yield.toFixed(1) : h.yield}</div>
									</div>
								</div>
							</div>
						))}
				</React.Fragment>
				: null)
		}
	}

	getAllCharts = (trial) => {
		if (trial.data.trial_type.includes("vs")) {
			let includedType = false;
			let swathTypesList = [];
			trial.hybrids.sort((a, b) => ((a.hybrid + a.swath_type) < (b.hybrid + b.swath_type)) ? -1 : ((a.hybrid + a.swath_type) > (b.hybrid + b.swath_type)) ? 1 : 0).forEach(h => {

				includedType = false;
				swathTypesList.forEach(s => {
					if (s[0] === h.swath_type) {
						includedType = true;
						return;
					}
				})
				if (!includedType) {
					swathTypesList.push([h.swath_type, h.swath_type_abbr]);
				}
			})

			return swathTypesList.map(t => {
				return (
					<div key={`${trial.data.trial}-${t}`} className="chartComparisonContainer">
						<div className="rowTitle">{`${t[0]} (${t[1]})`}</div>
						{this.getYieldChart(trial, t[0], t[1])}
					</div>)
			})
		}
		else {
			return this.getYieldChart(trial)
		}
	}

	getMaxYield = (hybrids) => {
		let maxYield = Math.max.apply(Math, hybrids.map(function (h) { return h.net_yield; }));
		return maxYield;
	}

	getMaxYieldapt = (treatments) => {
		let maxYield = Math.max.apply(Math, treatments.map(function (h) { return h.yield; }));
		return maxYield;
	}

	render() {
		const trial = this.props.trial;
		return (
			<React.Fragment>
				{trial ?
					<div className="individualTrialSummaryBody" 
						onClick={() => {
							this.generateTracker(this.props.trial.data.trial, 'Click', 'See Results - Individual Trial Button');
							this.props.clearIndividualTrial();
							this.props.page_router.loadPage('individualtrial', { trialCode: trial.data.trial })						
						}}  >
						<div className="tsBox">
							<div className="tsBoxTopBorder" style={{ border: `${TrialsCategory === 'DST' ? "3px solid #0061a1" : "3px solid #65ac1e"}` }}></div>
							<div className="tsBoxText" style={{ background: `${TrialsCategory === 'DST' ? "#dbf0fe" : "#f6ffed"}` }}>
							<div className='tsBoxContainer'>
								<div className='tsTextContainer'>
									<div className="subTSBoxText1">{trial.data.city}, {trial.data.province} 
											{trial.data.weather_exclude_from_summary && trial.data.weather_exclude_from_summary === true ? 
											<span className="weatherFlag">
												<FontAwesomeIcon icon={faFlag} /> 
											</span>
											: null }
									</div>
									<div>
										<div className="subTSBoxText">{TrialsCategory === 'DST' ? `Co-operator: ${trial.data.coopname}` : `Farm Name: ${trial.data.farm_name}`}</div>
										<div className="subTSBoxText">{TrialsCategory === 'DST' ? `${trial.data.trial_type.includes('Agronomic') ? `Agronomist` : `Technical Service Specialist`}: ${trial.data.agronomist}` : `Grower Name: ${trial.data.grower_name}`}</div>
									</div>
								</div>
								<div className="tsButtonContainer">							
									<div 
										className="tsBoxButton" 
										onClick={() => {
										this.generateTracker(this.props.trial.data.trial, 'Click', 'See Results - Individual Trial Button');
										this.props.clearIndividualTrial();
										this.props.page_router.loadPage('individualtrial', { trialCode: trial.data.trial })						
										}}  
									>
										<a
										data-tealium-category="trial-summary-button" 
										data-tealium-action="click" 
										data-tealium-value= {trial.data.trial_type+"||"+trial.data.trial}
										data-tealium-type="action"
										>MORE DETAILS</a>
									</div>
								</div>	
							</div>
								
							<div className="chartHeadContainer">
								{ (this.props.isHerbicide) || this.props.isFacet || this.props.isZidua || this.props.isHeatLQ ? " " : 	
									<div>
										<div className="chartHead"> Yield (bu/ac.) </div>
										<div className="chartHead-bracket-text"> (All yields have been adjusted for dockage & moisture) </div>
									</div>
								}
								</div> 
							
							
								<div className="chartHeaderTitle">{TrialsCategory === 'DST' ? trial.data.trial_type : trial.data.trial_name}
									<div className="year">{trial.data.year}</div>
								</div> 
							</div>

							{ (this.props.isHerbicide) || this.props.isFacet || this.props.isZidua || this.props.isHeatLQ ? " " :
								<div className="comparisonContent">
									<div className="chartRow">
										{TrialsCategory === 'DST' ? this.getAllCharts(trial) : this.getYieldChartapt(trial)}
									</div>
								</div>
							}

						</div>
					</div>
					: null
				}
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	clearIndividualTrial: () => dispatch(trialsActionCreators.clearIndividualTrial())
});


export default connect(null, mapDispatchToProps)(IndividualTrial);

